import React, { useState } from "react";
import "./publication.sass";
import ScrollToTopBtn from "../../Footer/ScrollToTopBtn";
import Underline from "../../Header/Underline";

const Publications = () => {

  return (
    <div style={{backgroundColor: '#E1FBFF'}}>
      <div 
        className="block_title" style={{backgroundColor: '#E1FBFF'}}>
          <p>Publications</p>
          <Underline />
      </div>

      <div className="publications">
        <div className="papers">
          
          <div className="paper-type">Journals</div>
          <p>[J1] Trung-Hieu Hoang, Mona Zehni, Huaijin Xu, George Heintz, Christopher Zallek, and Minh N. Do. “<i>Towards a Comprehensive Solution for a Vision-based Digitized Neurological Examination</i>” - IEEE Journal of Biomedical and Health Informatics (2022),  IF 5.8, DOI: 10.1109/JBHI.2022.3167927.</p>
          <p>[J2] Trung Thanh Nguyen, Truong Thao Nguyen, Thanh-Hung Nguyen, Phi Le Nguyen, “<i>Fuzzy Q-learning-based Opportunistic Communication for MEC-enhanced Vehicular Crowdsensing</i>”, IEEE Transactions on Network and Service Management, 2022. (Accepted)</p>
          <p>[J3] Khiem H Le, Hieu H Pham, Thao BT Nguyen, Tu A Nguyen, Tien N Thanh, Cuong D Do - “<i>LightX3ECG: A Lightweight and eXplainable Deep Learning System for 3-lead Electrocardiogram Classification</i>” - Biomedical Signal Processing and Control (Q1, IF 5.8).</p> 
          <p>[J4] Thao Nguyen, Hieu H. Pham, Huy Khiem Le, Anh Tu Nguyen, Ngoc Tien Thanh, Cuong Do Nguyen, Thao. “<i>Detecting COVID-19 from digitized ECG printouts using 1D convolutional neural networks</i>” PLOS ONE (Q1, IF 3.8), 2022.</p>
          <p>[J5] Anh Duy Nguyen,Huy Hieu Pham,Huynh Thanh Trung,Quoc Viet Hung Nguyen,Thao Nguyen Truong,Phi Le Nguyen, “<i>High accurate and explainable multi-pill detection framework with graph neural network-assisted multimodal data fusion</i>”, PLoS One, September 28, 2023.</p>
          <p>[J6] Quan Nguyen, Hieu H. Pham, Kok-Seng Wong, Phi Le Nguyen, Truong Thao Nguyen and Minh N. Do, “<i>FedDCT: Federated Learning of Large Convolutional Neural Networks on Resource Constrained Devices using Divide and Collaborative Training</i>”, IEEE Transactions on Network and Service Management, 2023.</p>

          <div className="paper-type">Conferences</div>
          <p>[C1] Nang Hung Nguyen, Phi Le Nguyen, Duc Long Nguyen, Trung Thanh Nguyen, Thuy Dung Nguyen, Thanh Hung Nguyen, Huy Hieu Pham, Truong Thao Nguyen. “<i>FedDRL: Deep Reinforcement Learning-based Adaptive Aggregation for Non-IID Data in Federated Learning</i>”, the 51st International Conference on Parallel Processing - ICPP (Rank A*) 2022.</p>
          <p>[C2] Anh Duy Nguyen, Thuy Dung Nguyen, Huy Hieu Pham, Thanh Hung Nguyen and Phi Le Nguyen, “<i>Image-based Contextual Pill Recognition with Medical Knowledge Graph Assistance</i>”, 14th Asian Conference on Intelligent Information and Database Systems (ACIIDS 2022).</p>
          <p>[C3] Trong-Tung Nguyen, Hieu H. Pham, Phi Le Nguyen, Thanh Hung Nguyen, Minh Do. “<i>Multi-stream Fusion for Class Incremental Learning in Pill Image Classification</i>” - The Asian Conference on Computer Vision (ACCV 2022).</p>
          <p>[C4] Khiem H. Le, Hieu H. Pham, Thao B.T. Nguyen, Tu A. Nguyen, Cuong D. Do. “<i>Enhancing Deep Learning-based 3-lead ECG Classification with Heartbeat Counting and Demographic Data Integration</i>” - The IEEE Engineering in Medicine and Biology Society (IEEE-EMBS 2022).</p>
          <p>[C5] Anh-Tu Nguyen, Thao Nguyen, Huy-Khiem Le, Huy-Hieu Pham, Cuong Do. “<i>A novel deep learning-based approach for sleep apnea detection using single-lead ECG signals</i>” - The Asia Pacific Signal and Information Processing Association Annual Summit and Conference 2022 (APSIPA ASC 2022).</p>
          <p>[C6] Van An Le, Nam Duong Tran, Phuong Nam Nguyen, Thanh Hung Nguyen, Phi Le Nguyen, Truong Thao Nguyen, Yusheng Ji, “<i>Enhancing the Generalization of Personalized Federated Learning with Multi-head Model and Ensemble Voting</i>”, 38th IEEE International Parallel & Distributed Processing Symposium (IPDPS 2024).</p>
          <p>[C7] Quang Ha Pham, Nguyen Nang Hung, Hieu Pham, Nguyen Thanh-Hung, Truong Thao Nguyen, Phi Le Nguyen, “<i>SEM: A Simple Yet Efficient Model-agnostic Local Training Mechanism to Tackle Data Sparsity and Scarcity in Federated Learning</i>”, The Eleventh International Symposium on Computing and Networking (CANDAR), 2024 (Best paper reward).</p>
          <p>[C8] Thuy Dung Nguyen, Anh Duy Nguyen, Thanh-Hung Nguyen, Kok-Seng Wong, Huy Hieu Pham, Truong Thao Nguyen, Phi Le Nguyen, “<i>FedGrad: Mitigating Backdoor Attacks in Federated Learning Through Local Ultimate Gradients Inspection</i>”, 2023 International Joint Conference on Neural Networks (IJCNN)</p>
          <p>[C9] Nang Hung Nguyen, Duc Long Nguyen, Trong Bang Nguyen, Thanh-Hung Nguyen, Hieu Pham, Truong Thao Nguyen and Phi Le Nguyen, “<i>CADIS: Handling Cluster-skewed Non-IID Data in Federated Learning with Clustered Aggregation and Knowledge Distilled Regularization</i>”, The 23nd IEEE International Symposium on Cluster, Cloud and Internet Computing (CCGrid 2023) (Best paper finalist).</p>
          
          <div className="paper-type">Book chapters</div>
          <p>[B1] Duc A. Tran, My T. Thai, and Bhaskar Krishnamachari (eds). “<i>Blockchain in a nutshell</i>” - Preprint. Book chapter (50 pages) in "Handbook on Blockchain" - Springer Nature Publisher, 2022.</p>
        </div>
      </div>
      <ScrollToTopBtn />
    </div>
  );
};

export default Publications;