import React from "react";
import ScrollToTopBtn from "../../Footer/ScrollToTopBtn";
import Underline from "../../Header/Underline";
import Iframe from 'react-iframe'
import { Col, Row } from "antd";
import "./products.sass";

const Products = () => {
    return (
        <>
            <div style={{backgroundColor: '#E1FBFF'}}>
                <div
                    className="block_title" style={{ backgroundColor: '#E1FBFF' }}>
                    <p>Products</p>
                    <Underline />
                </div>
                <div className="products">
                    <div className="product_title">
                        <Row style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "12px",
                            marginBottom: "12px",
                            marginLeft: "12px",
                            marginRight: "12px",
                        }}>
                            <Col span={12}>
                                <div className="product_type">VAIPE - An Intelligent Health Care System for Vietnamese People</div>
                            </Col>
                            <Col span={12}>
                                <Iframe 
                                    width="100%"
                                    className="product_video"
                                    url="https://www.youtube.com/embed/VwrmflrjJAE?si=kcAW_7ca5PnMeXNJ">
                                </Iframe>
                            </Col>
                        </Row>
                    </div>
                    <div className="product_title">
                        <Row style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "12px",
                            marginBottom: "12px",
                            marginLeft: "24px",
                            marginRight: "24px",
                        }}>
                            <Col span={12}>
                                <div className="product_type">Creating 3D Anatomical Models with AI-based Medical Imaging Data</div>
                            </Col>
                            <Col span={12}>
                                <Iframe 
                                    width="100%"
                                    className="product_video"
                                    url="https://www.youtube.com/embed/hfKXsuM0FmQ?si=FMFNnD03Y2-6aK_W">
                                </Iframe>
                            </Col>
                        </Row>
                    </div>
                    <div className="product_title">
                        <Row style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "12px",
                            marginBottom: "12px",
                            marginLeft: "24px",
                            marginRight: "24px",
                        }}>
                            <Col span={12}>
                                <div className="product_type">AI System that Estimates Critical Gait Parameters from Single-View RGB Videos</div>
                            </Col>
                            <Col span={12}>
                                <Iframe 
                                    width="100%"
                                    className="product_video"
                                    url="https://www.youtube.com/embed/1wHkU03zLeQ?si=L7SHDxw3S7xhCrUQ">
                                </Iframe>
                            </Col>
                        </Row>
                    </div>
                    <div className="product_title">
                        <Row style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "12px",
                            marginBottom: "12px",
                            marginLeft: "24px",
                            marginRight: "24px",
                        }}>
                            <Col span={12}>
                                <div className="product_type">VAIPE OCR</div>
                            </Col>
                            <Col span={12}>
                                <Iframe 
                                    width="100%"
                                    className="product_video"
                                    url="https://www.youtube.com/embed/fZ0HoKPrsvs?si=MG2CNGfCTKq64hZi">
                                </Iframe>
                            </Col>
                        </Row>
                    </div>
                    <div className="product_title">
                        <Row style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "12px",
                            marginBottom: "12px",
                            marginLeft: "24px",
                            marginRight: "24px",
                        }}>
                            <Col span={12}>
                                <div className="product_type">VAIPE CT2PET</div>
                            </Col>
                            <Col span={12}>
                                <Iframe 
                                    width="100%"
                                    className="product_video"
                                    url="https://www.youtube.com/embed/IOGpcOrol24?si=nR0tlM6cCE0KJUUL">
                                </Iframe>
                            </Col>
                        </Row>
                    </div>
                    <div className="product_title">
                        <Row style={{
                            display: "flex",
                            justifyContent: "space-around",
                            marginTop: "12px",
                            marginBottom: "12px",
                            marginLeft: "24px",
                            marginRight: "24px",
                        }}>
                            <Col span={12}>
                                <div className="product_type">VAIPE APP DEMO</div>
                            </Col>
                            <Col span={12}>
                                <Iframe 
                                    width="100%"
                                    className="product_video"
                                    url="https://www.youtube.com/embed/myg6l5tghaY?si=zNM1r57S_VXoSKyf">
                                </Iframe>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Products;