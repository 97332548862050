const member_list = [
  {
    title: "Healthcare Data Mining",
    member: [
      {
        member_name: "Professor Minh Do",
        member_image: require("../../images/body/team/member/Minh_Do.svg"),
        school: 'VinUniversity/University of Illinois at Urbana-Champaign'
      },
      {
        member_name: "Professor My Thai",
        member_image: require("../../images/body/team/member/My_Thai.svg"),
        school: 'University of Florida'
      },
      {
        member_name: "Professor Duc Tran",
        member_image: require("../../images/body/team/member/Duc_Tran.svg"),
        school: 'VinUniversity/University of Massachusetts'
      },
      {
        member_name: "Dr. Nguyen Thanh Hung",
        member_image: require("../../images/body/team/member/Thanh_Hung.svg"),
        school: 'Hanoi University of Science and Technology'
      },
      {
        member_name: "Dr. Nguyen Phi Le",
        member_image: require("../../images/body/team/member/Nguyen_Phi_Le.svg"),
        school: 'Hanoi University of Science and Technology'
      },
      {
        member_name: "Dr. Pham Huy Hieu",
        member_image: require("../../images/body/team/member/Hieu_Pham.svg"),
        school: 'VinUni-Illinois Smart Health Center/VinUniversity'
      }
    ]
  },
  {
    title: "User Privacy And Engagement",
    member: [
      {
        member_name: "Dr. Pham Huy Hieu",
        member_image: require("../../images/body/team/member/Hieu_Pham.png"),
        school: 'VinUni-Illinois Smart Health Center/VinUniversity'
      },
      {
        member_name: "Professor Minh Do",
        member_image: require("../../images/body/team/member/Minh_Do.png"),
        school: 'VinUniversity/University of Illinois at Urbana-Champaign'
      },


    ]
  },
  {
    title: "Visual Recognition Of Medication",
    member: [

      {
        member_name: "Dr. Nguyen Phi Le",
        member_image: require("../../images/body/team/member/Nguyen_Phi_Le.jpg"),
        school: 'Hanoi University of Science and Technology'
      },
      {
        member_name: "Professor Minh Do",
        member_image: require("../../images/body/team/member/Minh_Do.png"),
        school: 'VinUniversity/University of Illinois at Urbana-Champaign'
      },

    ]
  },
  {
    title: "Vietnamese OCR",
    member: [
      {
        member_name: "Dr. Nguyen Phi Le",
        member_image: require("../../images/body/team/member/Nguyen_Phi_Le.jpg"),
        school: 'Hanoi University of Science and Technology'
      },
      {
        member_name: "Dr. Pham Huy Hieu",
        member_image: require("../../images/body/team/member/Hieu_Pham.png"),
        school: 'VinUni-Illinois Smart Health Center/VinUniversity'
      },
      {
        member_name: "Dr. Nguyen Thanh Hung",
        member_image: require("../../images/body/team/member/Nguyen_Thanh_Hung.png"),
        school: 'Hanoi University of Science and Technology'
      },
      {
        member_name: "Professor Minh Do",
        member_image: require("../../images/body/team/member/Minh_Do.png"),
        school: 'VinUniversity/University of Illinois at Urbana-Champaign'
      }
    ]
  },
  {
    title: 'Elastic Resource Allocation',
    member: [
      {
        member_name: "Professor Minh Do",
        member_image: require("../../images/body/team/member/Minh_Do.png"),
        school: 'VinUniversity/University of Illinois at Urbana-Champaign'
      },
      {
        member_name: "Dr. Nguyen Thanh Hung",
        member_image: require("../../images/body/team/member/Nguyen_Thanh_Hung.png"),
        school: 'Hanoi University of Science and Technology'
      },
      {
        member_name: "Dr. Nguyen Phi Le",
        member_image: require("../../images/body/team/member/Nguyen_Phi_Le.jpg"),
        school: 'Hanoi University of Science and Technology'
      }
    ]
  }
];

const member_list_new = [
  {
    member: [
      {
        member_name: "Professor Minh Do",
        member_image: require("../../images/body/team/member/Minh_Do.svg"),
        school: 'VinUniversity/University of Illinois at Urbana-Champaign'
      },
      {
        member_name: "Professor My Thai",
        member_image: require("../../images/body/team/member/My_Thai.svg"),
        school: 'University of Florida'
      },
      {
        member_name: "Professor Duc Tran",
        member_image: require("../../images/body/team/member/Dr_Duc_Tran.png"),
        school: 'VinUniversity/University of Massachusetts'
      },
      {
        member_name: "Dr. Nguyen Thanh Hung",
        member_image: require("../../images/body/team/member/Thanh_Hung.svg"),
        school: 'Hanoi University of Science and Technology'
      },
      {
        member_name: "Dr. Nguyen Phi Le",
        member_image: require("../../images/body/team/member/Nguyen_Phi_Le.jpg"),
        school: 'Hanoi University of Science and Technology'
      },
      {
        member_name: "Dr. Pham Huy Hieu",
        member_image: require("../../images/body/team/member/Hieu_Pham.svg"),
        school: 'VinUni-Illinois Smart Health Center/VinUniversity'
      }
    ]
  }
];

const student_list = [
  {
    member: [
      {
        member_name: "Phan Nhat Huy",
        member_image: require("../../images/body/team/student/Phan_Nhat_Huy.jpg")
      },
      {
        member_name: "Nguyen Anh Duy",
        member_image: require("../../images/body/team/student/Nguyen_Anh_Duy.jpg")
      },
      {
        member_name: "Nguyen Trong Tung",
        member_image: require("../../images/body/team/student/Nguyen_Trong_Tung.jpg")
      },
      {
        member_name: "Nguyen Thuy Dung",
        member_image: require("../../images/body/team/student/Nguyen_Thuy_Dung.jpg")
      },
      {
        member_name: "Nguyen Thi Ngoc Huyen",
        member_image: require("../../images/body/team/student/Nguyen_Thi_Ngoc_Huyen.jpg")
      },
      {
        member_name: "Nguyen Xuan Anh",
        member_image: require("../../images/body/team/student/Nguyen_Xuan_Anh.jpg")
      },
      {
        member_name: "Nguyen Viet Huy",
        member_image: require("../../images/body/team/student/Nguyen_Viet_Huy.jpg")
      },
      {
        member_name: "Nguyen Trung Thanh",
        member_image: require("../../images/body/team/student/Nguyen_Trung_Thanh.jpg")
      },
      {
        member_name: "Nguyen Duc Long",
        member_image: require("../../images/body/team/student/Nguyen_Duc_Long.jpg")
      },
      {
        member_name: "Doan Ngoc Khanh",
        member_image: require("../../images/body/team/student/Doan_Ngoc_Khanh.jpg")
      },
      {
        member_name: "Pham Quang Ha",
        member_image: require("../../images/body/team/student/Pham_Quang_Ha.jpg")
      },
      {
        member_name: "Nguyen Anh Duc",
        member_image: require("../../images/body/team/student/Nguyen_Anh_Duc.jpg")
      },
      {
        member_name: "Nguyen Manh Hiep",
        member_image: require("../../images/body/team/student/Nguyen_Manh_Hiep.jpg")
      },
      {
        member_name: "Nguyen Nang Hung",
        member_image: require("../../images/body/team/student/Nguyen_Nang_Hung.jpg")
      },
      {
        member_name: "Vu Minh Hai",
        member_image: require("../../images/body/team/student/Vu_Minh_Hai.jpg")
      },
      {
        member_name: "Le Xuan An",
        member_image: require("../../images/body/team/student/Le_Xuan_An.jpg")
      },
      {
        member_name: "Nguyen Duc Dat",
        member_image: require("../../images/body/team/student/Nguyen-Duc-Dat.jpg")
      },
      {
        member_name: "Nguyen Dong Duc Anh",
        member_image: require("../../images/body/team/student/Nguyen_Dong_Duc_Anh.jpg")
      },
      {
        member_name: "Chu Manh Hai",
        member_image: require("../../images/body/team/student/Chu_Manh_Hai.jpg")
      }
    ]
  }
]

const student_list_new = [
  {
    member: [
      {
        member_name: "Phan Nhat Huy",
        member_image: require("../../images/body/team/student_new/Phan_Nhat_Huy.jpg")
      },
      {
        member_name: "Nguyen Anh Duy",
        member_image: require("../../images/body/team/student_new/Nguyen_Anh_Duy.jpg")
      },
      {
        member_name: "Nguyen Trong Tung",
        member_image: require("../../images/body/team/student_new/Nguyen_Trong_Tung.jpg")
      },
      {
        member_name: "Nguyen Thuy Dung",
        member_image: require("../../images/body/team/student_new/Nguyen_Thuy_Dung.jpg")
      },
      {
        member_name: "Nguyen Thi Ngoc Huyen",
        member_image: require("../../images/body/team/student_new/Nguyen_Thi_Ngoc_Huyen.jpg")
      },
      {
        member_name: "Nguyen Xuan Anh",
        member_image: require("../../images/body/team/student_new/Nguyen_Xuan_Anh.jpg")
      },
      {
        member_name: "Nguyen Viet Huy",
        member_image: require("../../images/body/team/student_new/Nguyen_Viet_Huy.jpg")
      },
      {
        member_name: "Nguyen Trung Thanh",
        member_image: require("../../images/body/team/student_new/Nguyen_Trung_Thanh.jpg")
      },
      {
        member_name: "Nguyen Duc Long",
        member_image: require("../../images/body/team/student_new/Nguyen_Duc_Long.jpg")
      },
      {
        member_name: "Doan Ngoc Khanh",
        member_image: require("../../images/body/team/student_new/Doan_Khanh.png")
      },
      {
        member_name: "Pham Quang Ha",
        member_image: require("../../images/body/team/student_new/Pham_Quang_Ha.jpg")
      },
      {
        member_name: "Nguyen Anh Duc",
        member_image: require("../../images/body/team/student_new/Nguyen_Anh_Duc.jpeg")
      },
      {
        member_name: "Nguyen Manh Hiep",
        member_image: require("../../images/body/team/student_new/Nguyen_Manh_Hiep.jpg")
      },
      {
        member_name: "Nguyen Minh Quan",
        member_image: require("../../images/body/team/student_new/Nguyen_Minh_Quan.jpg")
      },
      {
        member_name: "Nguyen Nang Hung",
        member_image: require("../../images/body/team/student_new/Nguyen_Nang_Hung.jpg")
      },
      {
        member_name: "Vu Minh Hai",
        member_image: require("../../images/body/team/student_new/Vu_Minh_Hai.jpg")
      },
      {
        member_name: "Le Xuan An",
        member_image: require("../../images/body/team/student_new/Le_Xuan_An.jpg")
      },
      {
        member_name: "Nguyen Duc Dat",
        member_image: require("../../images/body/team/student_new/Nguyen-Duc-Dat.jpg")
      },
      {
        member_name: "Nguyen Dong Duc Anh",
        member_image: require("../../images/body/team/student_new/Nguyen_Dong_Duc_Anh.jpg")
      },
      {
        member_name: "Chu Manh Hai",
        member_image: require("../../images/body/team/student_new/Chu_Manh_Hai.jpg")
      },
      {
        member_name: "Pham Ngoc Bao Anh",
        member_image: require("../../images/body/team/student_new/Pham_Ngoc_Bao_Anh.jpg")
      },
    ]
  }
]

const research_assistants = [
  {
    member: [
      {
        member_name: "Nguyen Anh Duy",
        member_image: require("../../images/body/team/student_new/Nguyen_Anh_Duy.jpg")
      },
      {
        member_name: "Nguyen Minh Quan",
        member_image: require("../../images/body/team/student_new/Nguyen_Minh_Quan.jpg")
      },
      {
        member_name: "Nguyen Trung Thanh",
        member_image: require("../../images/body/team/student_new/Nguyen_Trung_Thanh.jpg")
      },
      {
        member_name: "Nguyen Nang Hung",
        member_image: require("../../images/body/team/student_new/Nguyen_Nang_Hung.jpg")
      },
      {
        member_name: "Nguyen Trong Tung",
        member_image: require("../../images/body/team/student_new/Nguyen_Trong_Tung.jpg")
      },
      {
        member_name: "Nguyen Thuy Dung",
        member_image: require("../../images/body/team/student_new/Nguyen_Thuy_Dung.jpg")
      },
      {
        member_name: "Phan Nhat Huy",
        member_image: require("../../images/body/team/student_new/Phan_Nhat_Huy.jpg")
      },
      {
        member_name: "Nguyen Duc Long",
        member_image: require("../../images/body/team/student_new/Nguyen_Duc_Long.jpg")
      },
      {
        member_name: "Nguyen Thi Ngoc Huyen",
        member_image: require("../../images/body/team/student_new/Nguyen_Thi_Ngoc_Huyen.jpg")
      },
      {
        member_name: "Doan Ngoc Khanh",
        member_image: require("../../images/body/team/student_new/Doan_Khanh.png")
      },
      {
        member_name: "Nguyen Manh Hiep",
        member_image: require("../../images/body/team/student_new/Nguyen_Manh_Hiep.jpg")
      },
      {
        member_name: "Vu Minh Hai",
        member_image: require("../../images/body/team/student_new/Vu_Minh_Hai.jpg")
      },
      {
        member_name: "Nguyen Xuan Anh",
        member_image: require("../../images/body/team/student_new/Nguyen_Xuan_Anh.jpg")
      }
    ]
  }
]

const infra_engineers = [

]

const developers = [
  {
    member: [
      {
        member_name: "Chu Manh Hai",
        member_image: require("../../images/body/team/student_new/Chu_Manh_Hai.jpg")
      },
      {
        member_name: "Pham Ngoc Bao Anh",
        member_image: require("../../images/body/team/student_new/Pham_Ngoc_Bao_Anh.jpg")
      },
      {
        member_name: "Le Xuan An",
        member_image: require("../../images/body/team/student_new/Le_Xuan_An.jpg")
      },
      {
        member_name: "Nguyen Viet Huy",
        member_image: require("../../images/body/team/student_new/Nguyen_Viet_Huy.jpg")
      },
      {
        member_name: "Pham Quang Ha",
        member_image: require("../../images/body/team/student_new/Pham_Quang_Ha.jpg")
      },
      {
        member_name: "Nguyen Anh Duc",
        member_image: require("../../images/body/team/student_new/Nguyen_Anh_Duc.jpeg")
      },
      {
        member_name: "Nguyen Duc Dat",
        member_image: require("../../images/body/team/student_new/Nguyen-Duc-Dat.jpg")
      },
      {
        member_name: "Nguyen Dong Duc Anh",
        member_image: require("../../images/body/team/student_new/Nguyen_Dong_Duc_Anh.jpg")
      }
    ]
  }
]

module.exports = { member_list_new, student_list, student_list_new, research_assistants, infra_engineers, developers };